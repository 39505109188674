<script>

export default {
  props: {
    liquidacionPersonaId: {
      required: true
    }
  },
  data() {
    return {
      cuerpoDeConsulta: undefined,
      persona: undefined,
      nominaLiquidacionPersona: undefined,
      resultConsultaAportesEnLinea: undefined,
      waitingConsulta: false


    }
  },
  async mounted() {
    this.waitingConsulta = true
    const path = `LiquidadorNomina/GetCuerpoConsultaLiquidacionNominaAportesEnLinea/${this.liquidacionPersonaId}`
    const response = await this.$store.getters.fetchGet({ path })
    const { body, persona, nominaLiquidacionPersona } = await response.json()
    this.cuerpoDeConsulta = body
    this.persona = persona
    
    this.nominaLiquidacionPersona = nominaLiquidacionPersona

    if (nominaLiquidacionPersona.estadoAportes == 'Pendiente') {
      await this.consultarPersonaEnAportes()
    }
    this.waitingConsulta = false
  },
  methods: {

    async consultarPersonaEnAportes() {
      
      const token = await this.$store.getters.getTokenAportes()
      const response = await fetch(`${this.$store.state.APIAportes}/NominaElectronica/ConsultaEstado`, {
        method: 'POST',
        headers: {
          'accept': 'application/json',
          'token': token,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(this.cuerpoDeConsulta)
      })
      const result = await response.json()

      if (result.estado == "En validación") {
        const responseSaveing = await this.$store.getters.fetchPost({
          path: `LiquidadorNomina/SaveResultConsultaAportesEnLineaIndividual/${this.liquidacionPersonaId}`,
          data: {
            trabajador: result
          }
        })
        if (responseSaveing.ok) {
          console.log('ok')
        } else {
          console.log('nop')
        }
      } else {
        const responseSaveing = await this.$store.getters.fetchPost({
          path: `LiquidadorNomina/SaveResultConsultaAportesEnLineaIndividual/${this.liquidacionPersonaId}`,
          data: result
        })
        if (responseSaveing.ok) {
          console.log('ok')
        } else {
          console.log('nooo')
        }
      }
      this.resultConsultaAportesEnLinea = result
    },
  }
}

</script>

<template>
  <div class="container-fluid">
    <b-card style="min-height: 250px">
      <div v-if="nominaLiquidacionPersona && nominaLiquidacionPersona.estadoAportes != 'Pendiente'">
        <h3 class="mb-3">
          {{ nominaLiquidacionPersona.estadoAportes }}
          <span>
            <i class="fas fa-solid fa-clock text-warn" v-if="nominaLiquidacionPersona.estadoAportes == 'Pendiente'"></i>
            <i v-else-if="nominaLiquidacionPersona.estadoAportes == 'Aprobado'" class="fas fa-solid fa-check px-1 text-success pointer"></i>
            <i v-else class="fas fa-solid fa-exclamation-triangle px-1 text-danger pointer"> </i>
          </span>
        </h3>
        <div>
          <P class="mb-1">
            {{ `${nominaLiquidacionPersona.persona.nombres} ${nominaLiquidacionPersona.persona.apellidos}` }}
          </P>

        </div>
      </div>
      <div v-else>
        <h3 class="mb-3">
          {{ waitingConsulta ? 'Consultando estado' : resultConsultaAportesEnLinea?.trabajador[0].mensajes[0].tipo }}
          <span>
            <i v-if="waitingConsulta" class="fas fa-solid fa-spinner fa-spin text-warn"></i>
            <i v-else-if="resultConsultaAportesEnLinea?.trabajador[0].mensajes[0].tipo== 'Pendiente'" class="fas fa-solid fa-clock px-1 text-warn" ></i>
            <i v-else-if="resultConsultaAportesEnLinea?.trabajador[0].mensajes[0].tipo == 'Aprobado'" class="fas fa-solid fa-check px-1 text-success "></i>
            <i v-else-if="resultConsultaAportesEnLinea?.trabajador[0].mensajes[0].tipo == 'Rechazado'" class="fas fa-solid fa-check px-1 text-danger"></i>

          </span>
        </h3>
        <div v-if="nominaLiquidacionPersona">
          <P class="mb-1">
            {{ `${nominaLiquidacionPersona.persona.nombres} ${nominaLiquidacionPersona.persona.apellidos}` }}
          </P>

        </div>
      </div>
    </b-card>
  </div>
</template>